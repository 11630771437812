/* @font-face {
    font-family: "Bebas";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Bebas-Regular.otf");
}

@font-face {
    font-family: "BebasNeue";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/BebasNeue-Regular.ttf");
}

@font-face {
    font-family: "BebasNeue";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/BebasNeue-Regular.ttf");
} */

@font-face {
    font-family: "ABITE";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/ABITE.ttf");
}

@font-face {
    font-family: "AbrilFatface";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/AbrilFatface-Regular.ttf");
}

@font-face {
    font-family: "Alternate Gothic No1 D";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Alternate+Gothic+No1+D+Regular.otf");
}

@font-face {
    font-family: "Anton";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Anton-Regular.ttf");
}

@font-face {
    font-family: "Antonio";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Antonio-VariableFont_wght.ttf");
}

@font-face {
    font-family: "Arizonia";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Arizonia-Regular.ttf");
}

@font-face {
    font-family: "Astagina";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Astagina+Signature.otf");
}

@font-face {
    font-family: "Bebas";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Bebas-Regular.otf");
}

@font-face {
    font-family: "BebasNeue";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/BebasNeue-Regular.ttf");
}

@font-face {
    font-family: "Chunk";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Chunk.otf");
}

@font-face {
    font-family: "CrimsonPro";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/CrimsonPro-VariableFont_wght.ttf");
}

@font-face {
    font-family: "FUTRFW";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/FUTRFW.TTF");
}

@font-face {
    font-family: "Inter";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Inter-VariableFont_slnt%2Cwght.ttf");
}

@font-face {
    font-family: "KeplerStd";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/KeplerStd-Regular.otf");
}

@font-face {
    font-family: "Montserrat";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Montserrat-VariableFont_wght.ttf");
}

@font-face {
    font-family: "ObjektivMk1";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/ObjektivMk1_Trial_XBd.ttf");
}

@font-face {
    font-family: "PaytoneOne";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/PaytoneOne-Regular.ttf");
}

@font-face {
    font-family: "PlayfairDisplaySC-Bold";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/PlayfairDisplaySC-Bold.ttf");
}

@font-face {
    font-family: "PlayfairDisplaySC";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/PlayfairDisplaySC-Regular.ttf");
}

@font-face {
    font-family: "PlusJakartaSans";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/PlusJakartaSans-VariableFont_wght.ttf");
}

@font-face {
    font-family: "Poppins-Bold";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Poppins-Bold.ttf");
}

@font-face {
    font-family: "Poppins-ExtraBold";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Poppins-ExtraBold.ttf");
}

@font-face {
    font-family: "Poppins-Light";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Poppins-Light.ttf");
}

@font-face {
    font-family: "Poppins";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Poppins-Regular.ttf");
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Poppins-SemiBold.ttf");
}

@font-face {
    font-family: "Quicksand";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Quicksand-VariableFont_wght.ttf");
}

@font-face {
    font-family: "Reglisse";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Reglisse_Back.otf");
}

@font-face {
    font-family: "SourceSansPro";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/SourceSansPro-Regular.ttf");
}

@font-face {
    font-family: "Turret";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/TurretRoad-ExtraBold.ttf");
}

@font-face {
    font-family: "Typo";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Typo+Draft+Demo.otf");
}

@font-face {
    font-family: "Urbanist";
    src: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/vooduu/fonts/Urbanist-VariableFont_wght.ttf");
}